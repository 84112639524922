

.cover-img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	transition: opacity 0.5s;

	&-enter {
		opacity: 0;
	}

	&-enter-active {
		opacity: 1;
	}

	&-leave {
		opacity: 0;
	}
}