.share-card {

	@include dialog;

	&-enter {
		opacity: 0;
	}

	&-enter-active {
		transition: $basic-transition;
		opacity: 1;
	}


	&-leave {
		pointer-events: none;
		opacity: 1;
	}

	&-leave-active {
		pointer-events: none;
		transition: $basic-transition;
		opacity: 0;
	}

	@media screen and (max-width: $bp-small) {
		width: 100%;
		height: 100%;
	}
}


.share-card__close {
	height: 15px;
	width: 15px;
	position: absolute;
	top: 12px;
	right: 10px;
	cursor: pointer;
	transition: .2s ease-in-out;
	color: $color-light-grey;
	&:hover {
		color: $color-light-white;
	}
}

.share-card__embed {
	width: 100%;
	//margin-top: 20px;
}

.share-card__buttons {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	button {
		border: 1px solid rgba(white,0.6);
		position: relative;
		color: rgba(white,0.6);
		height: auto;
		font-family: $font-stack;
		font-size: 10px;
		letter-spacing: 0.8px;
		background: transparent;
		padding: 8px 16px;
		text-transform: uppercase;
		align-items: center;
		display: flex;
		font-weight: 400;
		margin-right: 20px;
		//margin-bottom: 30px;
		cursor: pointer;
		outline: none;
		transition: $basic-transition;
		border-radius: $border-radius-dialog;
		@media screen and (max-width: $bp-small) {
			margin-bottom: 15px;
		}
		&:last-child {
			margin-right: 0;
		}
		&:hover {
			&.facebook {
				color: white;
				background: $color-facebook;
				border-color: $color-facebook;
			}
			&.twitter {
				color: white;
				background: $color-twitter;
				border-color: $color-twitter;
			}
			&.google-plus {
				color: white;
				background: $color-google-plus;
				border-color: $color-google-plus;
			}
			&.mail {
				color: white;
				background: $color-mail;
				border-color: $color-mail;
			}
		}
		span {
			@media screen and (max-width: $bp-small) {
					display: none;
			}
		}
	}
	i {
		font-size: 36px;
		@media screen and (max-width: $bp-medium) {
			font-size: 24px;
			margin: 0;
		}
		@media screen and (max-width: $bp-small) {
			font-size: 16px;
			margin: 0;
		}
	}
}


.share-card__text-line {
	font-family: $font-stack;
	color: white;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	width: 100%;
	display: flex;
	margin-bottom: 10px;
	.label {
		width: 60px;
		flex-shrink: 0;
		line-height: 31px;
	}

	input {
		margin-left: 10px;
		width: calc(100% - 40px);
		background: rgba(white,0.3);
		border: none;
		box-shadow: none;
		padding: 10px;
		color: white;
		cursor: copy;
		flex-shrink: 1;
		border-radius: $border-radius-dialog;
		outline: 0;
		&.input-sizewidth {
			width: 50px;
			flex-shrink: 0;
			cursor: default;
			&:nth-of-type(2){
				margin-left:0;
			}
		}
	}
	.size-separator {
		line-height: 32px;
    	margin: 0 5px;
	}
	@media screen and (max-width: $bp-small) {
		.label {
			width: 38px;
		}
		input {
			padding: 6px;
		}
		&.share-card__size {
			display: none;
		}
	}
}
.size-link{
  margin-bottom: 0px;
}

.share-card__content {
	width: 100%;
	height: 100%;
	padding: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media screen and (max-width: $bp-small) {
		padding: 20px 30px;
	}
}