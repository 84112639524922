.next-dialog {
	position: absolute;
	bottom: 50px;
	right: 0px;
	height: auto;
	padding: 20px;
	background: rgba(0,0,0,0.7);
	max-height: 200px;
	border-radius: $border-radius-dialog $border-radius-dialog 0 $border-radius-dialog;
	display: flex;
	align-items: center;
	border-radius: 5px;
	&:after {
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 8px 0 8px;
		border-color: rgba(black, 0.7) transparent transparent transparent;
		content: '';
		bottom: -7px;
		right: 10px;
		position: absolute;
	}
	@media screen and (max-width: $bp-small) {
		display: none;
	}
}

.next-dialog__img {
	width: 244px;
	height: 160px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: $border-radius-dialog;
	}
	i {
		position: absolute;
		width: 35px;
		height: 35px;
		border: 1px solid white;
		background: rgba(black, 0.3);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 1px 1px 1px rgba(black, 0.5);
		text-shadow: 0px 0px 3px rgba(black, 0.5);
		&:before {
			margin-left: 3px;
		}
	}
	@media screen and (max-width: $bp-medium) {
		height: 120px;
		width: 213px;
	}
}


.next-dialog__text {
	color: white;
	min-width: 200px;
	width: 100%;
	flex-shrink: 1;
	margin-left: 20px;
	overflow: hidden;
	text-align: left;
	display: flex;
	align-items: left;
	flex-direction: column;

	h2 {
		@include font-style-big;
		font-size: 18px;
	}
	h3 {
		@include font-style-heading-medium;
		color: #aaa;
		font-size: 14px;
	}
	p {
		@include font-style-p;
	}
	@media screen and (max-width: $bp-medium) {
		h2 {
			font-size: 14px;
		}
		h3 {
			font-size: 10px;
		}
		p {
		}
	}
}


