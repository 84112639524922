
.ad-roll__overlay {
	color: #FFF;
	font-size: 12px;
	display: flex;
	align-items: center;
	color: #FFF;
	transform: translateY(0px);



	&--overlay {
		position: absolute;
		bottom: 15px;
		left: 15px;
		border-radius: 5px;
		visibility: hidden;
		transition: transform 0.4s;
		
		.container--playing & {
			visibility: visible;
		}
		
		.container--show-controls & {
			//visibility: hidden;
			transform: translateY(-40px);
		}
		
	}


	&--control-bar {
		display: none;
		margin-left: 20px;
	}


	a {
		color: #FFF;
	}

	i {
		margin-left: 6px;
    font-size: 10px;
	}

	>span {
		display: block;
		padding: 0px 0px 0px 0px;
		display: flex;
		align-items: center;
		text-shadow: 0px 0px 2px rgba(#000,0.6);
		&:after {
			content: "\2022";
			padding: 0px 5px;
			font-size: 8px;
		}

		&:last-child:after {
			display: none;
		}
	}

	.control-bar & {
		position: relative;
		padding: 0;
		bottom: auto;
		top: auto;
	}

}

.ad-roll__skip {
	text-decoration: underline;
}


.container--is-playing-ad {
	.progress-bar,
	.hover-dialog--next,
	.control-bar__time,
	.control-bar__live {
		display: none;
	}

	&.vp-mode-live .control-bar__live {
		display: none;
	}
}