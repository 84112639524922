.dropdown {
	z-index: 200;
	position: relative;
	background: rgba(white,0.3);
	border: none;
	box-shadow: none;
	
	color: white;
	font-size: 11px;
	width: 200px;
	outline: none;
	border-radius: $border-radius-dialog;
	


	.dropdown__value-holder {
		display: flex;
		flex-direction: row;
		max-height: 31px;
		cursor: pointer;
	}
	.dropdown__value {
		flex-shrink: 1;
		width: 100%;
		padding: 10px;
	}
	.dropdown__pick {
		flex-shrink: 0;
		width: 31px;
		height: 31px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.dropdown__selector {
		display: flex;
		flex-direction: column;
		padding: 0;
		margin:0;
		background-color: rgba(20,20,20,1.0);
		box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
		border: 1px solid rgba(255,255,255, 0.2);
		width: 140px;
		max-height: 100px;
		overflow-y: scroll;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 31px;
		left: 0px;
		width:100%;
		transition: $basic-transition;
		border-radius: $border-radius-dialog;
	}
	.dropdown__option {
		color: rgba(255, 255, 255, 0.8);
		font-family: "Open Sans", sans-serif;
		font-size: 11px;
		letter-spacing: 0.8px;
		padding: 8px 5px 8px 10px;
		font-weight: 300;
		-webkit-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;
		cursor: pointer;
		width: 100%;
		&.dropdown__option--active {
			background-color: #333;
		}
	}
	&.dropdown--active {
		z-index: 9999;
		.dropdown__selector {
			opacity: 1.0;
			pointer-events: all;	
		}
		.dropdown__pick i {
			transform: rotate(180deg);
		}
	}
	&:hover {
		background: rgba(white,0.35);
	}
}
