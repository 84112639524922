
.video {
	
	left: 0;
	top: 0;
	width: 100%;
	height: auto;
	transition: $basic-transition;
	.container--show-squeeze-card & {
		border: 2px solid #000;
		box-shadow: 0px 0px 5px rgba(0,0,0,0);
		cursor: pointer;
		&:hover {
			transform: scale(1.03);
		}
	}
}


video::-webkit-media-controls {
  display:none !important;
}

.video-container {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transition: all 0.5s;
	align-items: center;
	justify-content: center;
	display: flex;

	.container--show-squeeze-card & {
		left: 20;
		top: 20;
		width: 30%;
		height: 30%;
		z-index: 12;
		@media screen and (max-width: $bp-medium) {
			width: 25%;
			height: 25%;
		}
	}
	
	.container--show-squeeze-card.container--show-share-card & {
		opacity: 0.0;
		pointer-events: none;
		transform: scale(1.2);

	}

	// Will disappear video rather than moving to top right
	.container--show-squeeze-card.container--no-squeeze & {
		opacity: 0;
		visibility: hidden;
		border: none;
		box-shadow: none;
		pointer-events: none;
		transform: none;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}


	i {
		opacity: 0;
		display: none;
	}
	.container--show-squeeze-card & i {
		display: inline-block;
		z-index: 14;
		pointer-events: none;
		position: absolute;
		width: 50px;
		height: 50px;
		border: 1px solid $color-light-grey;
		background: rgba(0, 0, 0, 0.3);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
		text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
		color: $color-light-grey;

		transition: $basic-transition;
		opacity: 1.0;

		&:before {
			margin-left: 3px;
		}
		@media screen and (max-width: $bp-small) {
			width: 40px;
			height: 40px;
		}
	}
	.container--show-squeeze-card.container--playing & i {
		opacity: 0;
	}
	&:hover {
		i {
			color: $color-light-white;
			border: 1px solid $color-light-white;
			transform: scale(1.2);
		}
	}
}

@media screen and (max-width: $bp-xsmall) {
	.container--show-squeeze-card {
		.video, .video-container {
			display: none;
			transition: none;
		}
	}
}