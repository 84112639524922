

* {
	box-sizing: border-box;
}

body {

}

.wrapper {
	max-width: 1200px;
	margin: 0px auto;
}



