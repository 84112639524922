.quality-dialog {
	position: absolute;
	bottom: 50px;
	right: -5px;
	height: auto;
	background: rgba(0,0,0,0.7);
	width: 110px;
	border-radius: $border-radius-dialog $border-radius-dialog 0 $border-radius-dialog;
	padding: 12px;
	border-radius: 5px;
	&:after {
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 8px 0 8px;
		border-color: rgba(black, 0.7) transparent transparent transparent;
		content: '';
		bottom: -7px;
		right: 10px;
		position: absolute;
	}

	ul {
		&.quality-settings {
			border-top: 1px solid rgba(255,255,255,.2);
			margin-top: 5px;
			li {
				margin-top:12px;
				.fa-cog {
						margin-right: 5px;
				  }
			}
			@media screen and (max-width: $bp-small) {
				display: none;
			}
		}
	}

	li {
		color: rgba(255, 255, 255, 0.8);
		font-family: $font-stack;
		font-size: 10px;
		text-transform: uppercase;
		letter-spacing: 0.8px;
		padding: 8px 5px 8px 0px;
		font-weight: 300;
		//transition: $basic-transition;
		cursor: pointer;

		&:hover {
				color: white;
		}

		&:first-child {
				padding-top: 0px;
		}

		&.active {
			color: $color-hilight;
			&:after {
			    content: "\EA06";
				font-family: "icons";
				vertical-align: middle;
				line-height: 0;
				font-weight: normal;
				font-style: normal;
				speak: none;
				text-decoration: inherit;
				text-transform: none;
				text-rendering: optimizeLegibility;
				-webkit-font-smoothing: antialiased;
				margin-left:3px;
				position: relative;
				top:-2px;
			}
		}
	}

}

.quality-dialog__title {
	color: white;
	font-family: $font-stack;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: .5px;
	text-align: left;
	margin-bottom: 12px;
}

.quality-dialog__settings {
	font-size: 12px;
	letter-spacing: 0.8px;
	font-weight: 600;
	font-family: $font-stack;
	display: flex;
	padding: 10px 10px;
	color: rgba(white, 0.6);
	cursor: pointer;
	border-top: 1px solid rgba(255,255,255,0.2);
	margin-top: 5px;
	padding: 0;
	li {
		margin-top:12px;
		margin-bottom: 0px;
		padding-bottom: 2px;
		.fa-cog {
			margin-right: 5px;
		}
	}
	&:hover {
			color: white;
	}
}

@media screen and (max-width: $bp-small) {
	.quality-dialog__settings {
		display: none;
	}
	.quality-dialog {
	}
}	

