.color-picker {
	display: flex;
	align-items: center;
	position: relative;
	left: -2;
}
.color-picker__box {
	width: 60px;
	height: 30px;
	border: 1px solid #999;
	border-radius: 1px;
	flex: 0 0 60px;
	cursor: pointer;
	margin-right: 20px;
	display: none;
}

.color-picker__palette-wrapper {
	position: relative;

}
.color-picker__palette {
	//position: absolute;
	//right: 0px;
	display: flex;
	transform: translateX(-100%);
	transition: transform 0.5s ease-out;
	align-items: center;
	.color-picker--open & {
		transform: translateX(0%);
	}

}
.color-picker__color-holder {
	height: 35px;
	width: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	&:last-of-type {
		margin-right: 0px;
	}
	&:hover {
		.color-picker__color {
			height: 35px;
			width: 35px;
		}
	}
}
.color-picker__color-holder--active {
	.color-picker__color {
		//border: 2px solid $color-hilight;
		//box-shadow: 0px 0px 1px 3px rgba(#FFF, 0.9);
		height: 35px;
		width: 35px;
		border: 2px solid rgba(255,255,255,1);
	}
}
.color-picker__color {
	position: relative;
	width: 30px;
	height: 30px;
	border: 2px solid rgba(#FFF, 0.2);
	border-radius: 50%;
	cursor: pointer;
	transition: .1s;
	//border: 2px solid rgba(#000, 0.1);
	border: 2px solid rgba(255,255,255,0.3);
}
