@mixin custom-control {
	user-select: none;
	outline: none;
}


@mixin dialog {
	background: rgba(0, 0, 0, 0.8);
	width: 84%;
	height: 72%;
	max-width: 600px;
	max-height: 300px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	border-radius: $border-radius-dialog;
	opacity: 1;
	border: 1px solid rgba(255,255,255,0.2);
}