.cc-card {

	@include dialog;

	@media screen and (max-width: $bp-medium) {
		top: 41%;
		height: 78%;
	}
	@media screen and (max-width: $bp-small) {
		top: 50%;
		height: 30vw;
	}


	&-enter {
		opacity: 0;
	}

	&-enter-active {
		transition: opacity 0.4s;
		opacity: 1;
	}


	&-leave {
		opacity: 1;
	}

	&-leave-active {
		transition: opacity 0.4s;
		opacity: 0;
	}

	ul {
		font-family: $font-stack;
		font-weight: 400;
		padding: 15px;
		letter-spacing: 0.5px;
		@media screen and (max-width: $bp-small) {
			// width: 100%;
			// display: flex;
			// flex-wrap: wrap;
			// justify-content: space-between;
			// padding: 20px 35px;

			display: none;
		}
	}
	li {
		margin-right: 40px;
		color: rgba(white, 0.6);
		cursor: pointer;
		display: inline-block;
		font-size: 15px;
		// position: relative;
		@media screen and (max-width: $bp-small) {
			display: flex;
			width: 48%;
			margin: 0;
			background: rgba(255,255,255,0.3);
			justify-content: center;
			align-items: center;

			&:first-child, &:nth-child(2) {
					margin-bottom: 10px;
			}
		}
		&.active {
			color: white;

			@media screen and (max-width: $bp-small) {
				display: flex;
				color: rgba(white, 0.6);

				&:hover {
						color: white;
				}
			}
		}
	}
	@media screen and (max-width: $bp-small) {
		display: none;
	}
}

	.cc-tab {
		color: rgba(white, 0.6);
		font-weight: 200;
		.cc-panel--active &,
		&:hover {
			color: white;
	}
}

.cc-window-wrapper {
	background-color: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	border-radius: $border-radius-dialog;
}

.cc-buttons {
	position: absolute;
	bottom: 14px;
	right: 10px;
	button {
		margin-right: 10px;
		border-radius: 1px;
		border: 1px solid white;
		background: transparent;
		padding: 6px 10px;
		color: white;
		font-size: 13px;
		background: none;
		outline: none;
		cursor: pointer;
		transition: background 0.3s;
		border-radius: $border-radius-dialog;
		&:hover {
			background: rgba(white, 0.9);
			color: black;
		}
	}
	@media screen and (max-width: $bp-small) {
		display: none;
	}
}

.options-details {
	width: 100%;
	position: absolute;
	height: calc(100% - 45px);
	left: 0;
	display: none;
	color: white;
	padding: 20px 20px 56px;
	margin: 15px 0 0;
	border-top: 1px solid rgba(255,255,255,0.2);
	cursor: default;
	align-items: center;

	@media screen and (max-width: $bp-small) {
		padding: 10px;
	}

	.cc-panel--active & {
		display: flex;
		@media screen and (max-width: $bp-small) {
				display: none;
		}
	}

	input {
		background: red;
	}
}

.options-details--vertical {
	display: none;
	.cc-panel--active & {
		display: block;
	}
}


.cc-options-close {
	height: 15px;
    width: 15px;
    position: relative;
    position: absolute;
    top: 12px;
    right: 10px;
    cursor: pointer;
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    color: $color-light-grey;
    &:hover {
		color: $color-light-white;
	}
}
.cc-options__field {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	label {
		flex: 0 0 130px;
		font-size: 12px;
		font-weight: 100;
	}
}

.presets {
	flex-wrap: wrap;
	justify-content: space-between;
	overflow-y: auto;

	> div {
		width: 46%;
		border: 2px solid transparent;
		cursor: pointer;
		font-size: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;
		height: 25%;


		&.selected {
			border: 2px solid $color-hilight;
		}

		@media screen and (max-width: $bp-small) {
			width: 100%;
			font-size: 20px;
		}
	}
}

.cc-preset--0 {
	background-color: rgba(white,1);
	color: rgba(black,1);
	font-family: Verdana;
	text-align: center;
	height: 50px;
	padding: 12px;
}
.cc-preset--1 {
	background-color: rgba(34,34,34,1);
	color: rgba(175,175,175,1);
	font-family: Courier New;
	text-shadow: -1px 1px 0px rgba(127,127,127,1), 1px -1px 0px rgba(black,1);
	text-align: center;
	height: 50px;
	padding: 12px;
}
.cc-preset--2 {
	background-color: rgba(137,170,200,1);
	color: rgba(white,1);
	font-family: Arial;
	text-shadow: 1px 1px 0px rgba(black,1), -1px 1px 0px rgba(black,1);
	text-align: center;
	height: 50px;
	padding: 12px;
}
.cc-preset--3 {
	background-color: rgba(black,1);
	color: rgba(85,194,183,1);
	font-family: Times New Roman;
	text-shadow: -1px -1px 0px black, 1px -1px 0px rgba(black,1), -1px 1px 0px rgba(black,1), 1px 1px 0px rgba(black,1);
	text-align: center;
	height: 50px;
	padding: 12px;
}
.cc-preset--4 {
	background-color: rgba(white,1);
	color: rgba(91,220,252,1);
	font-family: Arial;
	text-shadow: 1px 1px 0px rgba(127,127,127,1), -1px -1px 0px rgba(0,0,0,1);
	text-align: center;
	height: 50px;
	padding: 12px;
}
.cc-preset--5 {
	background-color: rgba(0,0,255,1);
	color: rgba(255,255,0,1);
	font-family: times new roman;
	text-shadow: -1px -1px 0px black, 1px -1px 0px rgba(black,1), -1px 1px 0px rgba(black,1), 1px 1px 0px rgba(black,1);
	text-align: center;
	height: 50px;
	padding: 12px;
}






.presets-preview {
		position: absolute;
		left: 0;
		bottom: -70px;
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;

		@media screen and (max-width: $bp-small) {
				bottom: -50px;
		}

		> div {
				cursor: pointer;
				font-size: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: auto;
				display: none;
				margin-bottom: 10px;

				@media screen and (max-width: $bp-small) {
						font-size: 12px;
				}

				&.selected {
						display: block;
				}
		}
}

.cc-preview-wrapper {
	position: absolute;
	left: 0;
	bottom: -70px;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	@media screen and (max-width: $bp-small) {
		display: none;
	}
}

.cc-preview {
	cursor: pointer;
	height: 50px;
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: auto;
	margin-bottom: 10px;

	@media screen and (max-width: $bp-small) {
			font-size: 12px;
	}
}

.iphone-message {
	position: absolute;
	color: rgba(white,0.6);
	font-family: $font-stack;
	letter-spacing: 1px;
	font-weight: 300;
	text-align: center;
	padding: 30px;
	line-height: 1.8;
	transform: translateY(-50%);
	top: 50%;
	font-size: 3.6vw;
	display: none;

	@media screen and (max-width: $bp-small) {
			display: block;
	}
}

.fcc {
	position: absolute;
	bottom: 15px;
	left: 20px;
	color: $color-hilight;
	text-decoration: none;
	font-family: $font-stack;
	letter-spacing: 0.6px;
	font-size: 10px;

	&:hover {
			text-decoration: underline;
	}
}
