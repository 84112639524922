.progress-bar {
	position: absolute;
	width: 100%;
	height: 4px;
	background: #676767;
	top: -4px;
	left: 0;
	box-shadow: inset 0 -5px 10px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	@include custom-control;
}

.progress-bar__buffered {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	background: #AAA;	
	transition: width 0.2s linear, left 0.2s linear;
}

.progress-bar__past {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
}



.progress-bar__handle {
	background: #fff;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
	top: -2px;
	border-radius: 50%;
	width: 8px;
	height: 8px;
	position: absolute;
	text-decoration: overline;
	left: 40%;
	transform: translateX(-50%);
	cursor: pointer;
	@include custom-control;
}

.container.container--is-touch {
	.progress-bar {
		height: 6px;
		top: -6px;
	}
	.progress-bar__handle {
		top:-4px;
		width: 14px;
		height: 14px;
	}
}