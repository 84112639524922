
$bp-xlarge: 1800px;
$bp-large: 1400px;
$bp-desktop: 1100px;
$bp-medium: 800px;
$bp-small: 600px;
$bp-xsmall: 480px;



$color-light-grey: rgba(255, 255, 255, 0.8);  
$color-light-white: rgba(255, 255, 255, 1.0);  
$color-hilight: #33b5d5;
$color-hilight-active: #7ef0ff;

$color-facebook: #3b5999;
$color-twitter: #55acee;
$color-google-plus: #dd4b39;
$color-mail: #ffab00;


$border-radius-dialog: 2px;  

$font-stack: 'Open Sans', sans-serif;
$basic-transition: all 0.2s ease-out;

